.hero-bottom{
    width: 90%;
    height: 80vh;
    /* background-color: red; */
    z-index: 1;
    margin: auto;
}

.hero-top{
    width: 90%;
    height: 100%;
    /* background-color: aqua; */
    margin: auto;
    font-size: 50px;
    z-index: 2;
    line-height: normal;
}

.hero-cool{
    display: flex;
}

.hero-cool-text{
    width: 70%;
    height: auto;
    /* background-color: aqua; */
}
.hero-canvas{
    width: 30%;
    background-color: transparent;
}
.hero-call{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


@media screen and (min-width: 320px) {
    .hero-top{
        font-size: 38px;
        width: 90%;
        margin-top: 20%;
    }

    .hero-call{
        margin-top: 30%;
    }
}

@media screen and (min-width: 768px) {
    .hero-top{
        margin-top: 20%;
        font-size: 60px;
        height: 70%;
    }
    .hero-call{
        margin-top: 20%;
    }
}

@media screen and (min-width: 1024px) {
    .hero-bottom{
        width: 100%;
        height: auto;
        /* background-color: red; */
        z-index: 1;
    }
    
    .hero-top{
        width: 70%;
        height: 100%;
        /* background-color: red; */
        margin: auto;
        font-size: 50px;
        z-index: 2;
    }
    
    .hero-cool{
        display: flex;
    }
    
    .hero-cool-text{
        width: 70%;
        /* background-color: aqua; */
    }
    .hero-canvas{
        width: 30%;
        background-color: transparent;
    }
    .hero-call{
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 0%;
    }
    
}   