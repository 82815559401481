input {
    /* font-family: 'Inter'; */
    width: 100px;
    border: 1px solid blue;
    background-image: none;
    background-color: transparent;
    color: transparent;
    caret-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-weight: 400;
    caret-color: blue;
    outline: auto;
  }