body{
    position: relative;
    scroll-behavior: smooth;
    color: aliceblue;
    background-color: transparent;
    animation: textColor 10s ease infinite;
}
.App{
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
}

.cen{
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-menu{
    z-index: 5;
    position: fixed;
    bottom: 30px;
    left:50vw
}

hr {
    margin-top: 100px;
    margin-bottom: 100px;
    border: none;
    border-top: 5px dotted black;
    height: 1px;
    width: 90%;
  }