.Card{
    padding: 5%;
    border-radius: 25px;
    width: fit-content;
    height: fit-content;
    line-height: auto;
    text-align: start;
    background-color: #151823;
    animation: textColor 10s ease infinite;
}

@media only screen and (max-width: 600px) {
  .Card{
    background-color: #151823b4;
    animation: textColorMini 10s ease infinite;
  }
}

@media only screen and (min-width: 600px) {
  .Card{
    font-size: 30px;
    background-color: #151823b4;
    animation: textColorMini 10s ease infinite;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Card{
    font-size: 20px;
    padding: 5%;
    border-radius: 25px;
    width: fit-content;
    height: fit-content;
    line-height: auto;
    text-align: start;
    background-color: #151823;
    animation: textColor 10s ease infinite;
}
}

.Card:after {
    position: absolute;
    content: "";
    top: 5vw;
    left: 0;
    right: 0;
    z-index: -1;
    height: auto;
    width: auto;
    margin: 0 auto;
    transform: scale(0.75);
    -webkit-filter: blur(5vw);
    -moz-filter: blur(5vw);
    -ms-filter: blur(5vw);
    filter: blur(5vw);
    background: linear-gradient(270deg, #0fffc1, #7e0fff);
    background-size: 200% 200%;
    animation: animateGlow 10s ease infinite;
  }
  @keyframes animateGlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

 @keyframes textColorMini{
  0% {
    color: #ece7e2;
  }
  50% {
    color: #e64b08;
  }
  100% {
    color: #ece7e2;
  }
 }

  @keyframes textColor {
    0% {
      color: #7e0fff;
    }
    50% {
      color: #0fffc1;
    }
    100% {
      color: #7e0fff;
    }
  }