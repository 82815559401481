/* Extra small devices (phones, 600px and down) */

.bounce {
    position: relative;
    margin-top: 50px;
    animation: bounce 2s ease infinite;
    cursor: pointer;
  }
  @keyframes bounce {
      70% { transform:translateY(0%); }
      80% { transform:translateY(-25%); }
      90% { transform:translateY(0%); }
      95% { transform:translateY(-7%); }
      97% { transform:translateY(0%); }
      99% { transform:translateY(-3%); }
      100% { transform:translateY(0); }
  }

@media only screen and (max-width: 600px) {
    .webdev{
        width: 100%;
        height: 200vh;
        z-index: 1;
        transition: all 0.5s ease;
        margin-top: 100%;
        /* overflow: hidden; */
    }
    .webdev-parent{
        height: 100%;
        width: 100%;
        position: relative;
        line-height: 220%;
    }
    .webdev-child{
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        scroll-snap-align: center;
        margin: auto;
    }
    .webdev-child-under{
        height: 50%;
        width: 80%;
        margin: auto;
        /* border: 1px solid black; */
        margin-bottom: 20px;
        /* background-color: rgba(255, 166, 0, 0.127); */
        transition: all 0.5s ease;
        z-index: 1;
        
        
    }

    .child-under-content{
        position: relative  ;
        /* background-color: rgba(0, 255, 255, 0.2); */
        font-size: 20px;
        top: 25%;
        left: 0%;
        width: 90%;
        height: auto;
        z-index: 4;
        display: flex;
        padding: 20px;
        justify-content: space-between;
        

    }
    .vert-line{
        margin:20px;
        background-color: black;
        width: 1.5px;
        height: 120vh;

    }
   
    .webdev .webdev-showcase{
        position: sticky;
        top: 0;
        height: 100vh;
        width: 100%;
        /* background-color: red; */
        z-index: 0; 
        transition: ease all; 
    }

    Canvas::-webkit-scrollbar{
        display: none;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .webdev{
        margin-top: 20vh;
        width: 100%;
        height: 200vh;
        z-index: 1;
        transition: all 0.5s ease;
        /* overflow: hidden; */
    }
    .webdev-parent{
        height: 100%;
        width: 100%;
        position: relative;
        line-height: 200%;
    }
    .webdev-child{
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        scroll-snap-align: center;
    }
    .webdev-child-under{
        height: 50%;
        width: 100%;
        /* border: 1px solid black; */
        margin-bottom: 20px;
        /* background-color: rgba(255, 166, 0, 0.127); */
        transition: all 0.5s ease;
        z-index: 1;
        
        
    }

    .child-under-content{
        position: relative  ;
        /* background-color: rgba(0, 255, 255, 0.2); */
        font-size: 20px;
        top: 25%;
        left: 0%;
        width: 90%;
        height: auto;
        z-index: 4;
        display: flex;
        padding: 20px;
        justify-content: space-between;
        

    }
    .vert-line{
        margin:20px;
        background-color: black;
        width: 1.5px;
        height: 120vh;

    }
   
    .webdev .webdev-showcase{
        position: sticky;
        top: 0;
        height: 100vh;
        width: 100%;
        /* background-color: red; */
        z-index: 0; 
        transition: ease all; 
    }

    Canvas::-webkit-scrollbar{
        display: none;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .webdev{
        margin-top: 20vh;
        width: 100%;
        height: 200vh;
        z-index: 1;
        transition: all 0.5s ease;
        /* overflow: hidden; */
    }
    .webdev-parent{
        height: 100%;
        width: 100%;
        position: relative;
    }
    .webdev-child{
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        scroll-snap-align: center;
    }
    .webdev-child-under{
        height: 50%;
        width: 100%;
        /* border: 1px solid black; */
        margin-bottom: 20px;
        /* background-color: rgba(255, 166, 0, 0.127); */
        transition: all 0.5s ease;
        z-index: 1;
        
        
    }

    .child-under-content{
        position: relative  ;
        /* background-color: rgba(0, 255, 255, 0.2); */
        font-size: 20px;
        top: 25%;
        left: 0%;
        width: 90%;
        height: auto;
        z-index: 4;
        display: flex;
        padding: 20px;
        justify-content: space-between;
        

    }
    .vert-line{
        margin:20px;
        background-color: black;
        width: 1.5px;
        height: 120vh;

    }
   
    .webdev .webdev-showcase{
        position: sticky;
        top: 0;
        height: 100vh;
        width: 100%;
        /* background-color: red; */
        z-index: 0; 
        transition: ease all; 
    }

    Canvas::-webkit-scrollbar{
        display: none;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .webdev{
        margin-top: 20vh;
        width: 100%;
        height: 200vh;
        z-index: 1;
        transition: all 0.5s ease;
        /* overflow: hidden; */
    }
    .webdev-parent{
        height: 100%;
        width: 100%;
        position: relative;
    }
    .webdev-child{
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        scroll-snap-align: center;
    }
    .webdev-child-under{
        height: 50%;
        width: 100%;
        /* border: 1px solid black; */
        margin-bottom: 20px;
        /* background-color: rgba(255, 166, 0, 0.127); */
        transition: all 0.5s ease;
        z-index: 1;
        
        
    }

    .child-under-content{
        position: relative  ;
        /* background-color: rgba(0, 255, 255, 0.2); */
        top: 25%;
        left: 50%;
        width: 40%;
        height: auto;
        z-index: 4;
        display: flex;
        padding: 20px;
        justify-content: space-between;
        

    }
    .vert-line{
        margin:20px;
        background-color: black;
        width: 1.5px;
        height: 120vh;

    }
   
    .webdev .webdev-showcase{
        position: sticky;
        top: 0;
        height: 100vh;
        width: 100%;
        /* background-color: red; */
        z-index: 0; 
        transition: ease all; 
    }

    Canvas::-webkit-scrollbar{
        display: none;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .webdev{
        margin-top: 20vh;
        width: 100%;
        height: 200vh;
        z-index: 1;
        transition: all 0.5s ease;
        /* overflow: hidden; */
    }
    .webdev-parent{
        height: 100%;
        width: 100%;
        position: relative;
    }
    .webdev-child{
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        scroll-snap-align: center;
    }
    .webdev-child-under{
        height: 50%;
        width: 100%;
        /* border: 1px solid red; */
        margin-bottom: 20px;
        /* background-color: rgba(255, 166, 0, 0.127); */
        transition: all 0.5s ease;
        z-index: 1;
        
        
    }

    .child-under-content{
        position: relative  ;
        /* background-color: rgba(0, 255, 255, 0.2); */
        top: 25%;
        left: 50%;
        width: 40%;
        height: auto;
        z-index: 4;
        display: flex;
        padding: 20px;
        justify-content: space-between;
        

    }
    .vert-line{
        margin:20px;
        background-color: black;
        width: 1.5px;
        height: 120vh;

    }
   
    .webdev .webdev-showcase{
        position: sticky;
        top: 0;
        height: 100vh;
        width: 100%;
        /* background-color: red; */
        z-index: 0; 
        transition: ease all; 
    }

    Canvas::-webkit-scrollbar{
        display: none;
    }
}


