.projectPage{
    height: 100vh;
    width: 100vw;
    z-index: 2;
}


.pro-detail{
  position: absolute;
  top: 1vh; 
  right: 1vw;
  font-size: 20px;
  z-Index:10;
  width: 30vw;
  font-weight: bold;
}


.pro-name{
  position: absolute;
  top: 40px;
  left: 40px;
  font-size: 20px;
  z-Index: 10;
  font-weight:bold;
}
li{
  margin-top: 20px;
}


/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
    /* Your CSS rules for extra small devices here */

    .pro-detail{
      top:45vh;
      left: 40px;
      width: 70vw;
      -webkit-text-stroke-color: black;
      -webkit-text-stroke-width: 0px;
      font-weight: bold;
      overflow: scroll;
    }
    .pro-name{
      top: 20px;
      font-size: 18px;
    }
  }
  
  /* Small devices (phones, 576px and up) */
  @media (min-width: 576px) {
    /* Your CSS rules for small devices here */
    .pro-detail{
      
    }
    .pro-name{
      top: 0;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    /* Your CSS rules for large devices here */
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    /* Your CSS rules for extra large devices here */
  }
  