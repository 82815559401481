
@import url('https://fonts.googleapis.com/css2?family=Mooli&family=Roboto+Condensed:ital@1&family=Roboto+Mono&display=swap');


body{
  margin: 0;
  padding: 0;
  font-family: 'Mooli', sans-serif;
  font-family: 'Roboto Condensed', sans-serif;
  font-family: 'Roboto Mono', monospace;
  
}