    .header{
        width: 90vw;
        display: flex;
        height: 50px;
        font-size: 48px;
        margin: 20px;
        /* background-color: green; */
        justify-content: space-between;
        align-items: center;
    }

    .headertime{
        font-size: 30px;
    }


@media screen and (min-width: 320px) {
    .header{
        width: 95vw;
        height: 30px;
        font-size: 15px;
        padding: 5px;
        /* background-color: pink; */
    }
    .headertime{
        font-size: 10px;
    }
}

@media screen and (min-width: 768px) {
    .header{
        width: 95vw;
        height: 40px;
        font-size: 36px;
        padding: 15px;
        /* background-color: red; */
    }
    .headertime{
        font-size: 20px;
    }
}

@media screen and (min-width: 1024px) {
    .header{
        width: 95vw;
        height: 40px;
        font-size: 36px;
        padding: 15px;
        /* background-color: orange; */
    }
    .headertime{
        font-size: 25px;
    }
}   

